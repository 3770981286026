.p-datatable {
	.p-datatable-wrapper {
		overflow: initial !important;
	}

	thead {
		position: sticky;
		top: 0;

		.status-filter {
			text-align: center;
		}

		&:hover {
			tr:nth-of-type(2) {
				display: table-row !important;
			}
		}
	}

	tbody {
		tr {
			&.p-row-odd {
				background-color: #ececec !important;
			}

			&:hover {
				background-color: #d9e9fc !important;
			}
		}

		td {
			border: none !important;
		}
	}
}

.sticker {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 50%;

	&.green {
		background-color: #19ab27;
	}

	&.gray {
		background-color: #4d4d4d;
	}
}

.p-paginator {
	.p-paginator-right-content {
		.p-button:hover {
			background-color: var(--main-blue-color) !important;
			color: white !important;
		}
	}

	.p-button-icon {
		margin-top: 1px;
	}
}

.p-inline-message {
	width: 100%;
}

.p-toast {
	opacity: 1 !important;
	z-index: 1000 !important;
}

.p-toast-detail {
	margin: 0 !important;
}
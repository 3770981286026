:root {
	--main-blue-color: #3b82f6;
	--dark-blue-color: #004aad;
	--light-blue-color: #00d9ff;
}

body {
	margin: 0;
	font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

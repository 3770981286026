.comments-zone {
	width: 400px;
	padding: 5px 5px 0 5px;

	.comments-zone-body {
		display: flex;
		flex-direction: column;
		height: 527px;
		overflow: auto;

		.comment {
			display: flex;
			flex-direction: column;
			margin: 0 20px 20px 10px;

			&.sub {
				align-self: flex-end;
				width: 80%;
			}

			.comment-infos {
				font-size: 0.7rem;
			}

			.comment-content {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 10px;
				background-color: #e9e9e9;
				font-size: 0.9rem;

				p {
					margin: 0;
					white-space: pre-wrap;
					word-break: break-all;
				}

				button {
					margin-left: 10px;
					background: none;
					color: #a6a6a6;
					border: none;
					cursor: pointer;
				}
			}

			.menu {
				align-self: flex-end;
			}

			.add-subcomment-zone {
				display: flex;
				width: 100%;
				border: 1px solid var(--dark-blue-color);

				textarea {
					height: 80px !important;
					width: 90%;
					overflow: auto !important;
					border-radius: 0;
					border: none;
					z-index: 0; // Just to be in front buttons
				}

				.add-subcomment-zone-buttons {
					width: 10%;
				}

				button {
					height: 50%;
					width: 100%;
					background-color: var(--dark-blue-color);
					color: white;
					border: none;
					cursor: pointer;

					&:hover {
						background-color: var(--main-blue-color);
					}

					i {
						font-size: 1.1rem;
					}
				}
			}
		}
	}

	.comments-zone-footer {
		height: 135px;
		margin-top: 5px;

		textarea {
			margin-bottom: 5px;
			height: 90px !important;
			width: 100%;
			overflow: auto !important;
			border-radius: 0;
		}

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 35px;
			width: 100%;
			background-color: var(--dark-blue-color);
			color: white;
			border: none;
			cursor: pointer;

			&:hover {
				background-color: var(--main-blue-color);
			}

			span {
				font-size: 1rem;
				font-weight: bold;
			}

			i {
				margin-left: 5px;
				transform: rotate(45deg);
			}
		}
	}
}

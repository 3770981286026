.license-modal-background {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 4;
	width: 100%;
	height: 100%;
	background-color: rgba(59, 130, 246, 0.8);

	.license-modal {
		background-color: white;

		.license-modal-actions {
			display: flex;
			justify-content: space-between;
			padding: 5px;
			border-bottom: 1px solid #dbdbdb;

			.arrows {
				display: flex;
				gap: 5px;

				.arrow {
					display: flex;
					justify-content: center;
					align-items: center;
					align-self: center;
					width: 35px;
					height: 35px;
					background-color: var(--dark-blue-color);
					color: white;
					border: none;

					&:hover {
						background-color: var(--main-blue-color);
						cursor: pointer;
					}
				}
			}

			.close-button {
				width: 35px;
				height: 35px;
				padding: 5px;
				background-color: var(--dark-blue-color);
				color: white;
				border: none;

				i {
					font-size: 1.5rem;
				}

				&:hover {
					background-color: var(--main-blue-color);
					cursor: pointer;
				}
			}
		}

		.license-modal-infos {
			display: flex;
			padding: 0 0 5px 5px;

			.license-info {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 10px;
				background-color: #dbdbdb;

				& > div {
					margin-bottom: 25px;
				}

				.copy-button {
					background: none;
					border: none;
					cursor: pointer;

					&:hover {
						color: grey;
					}

					i {
						font-size: 1.3rem;
					}
				}

				.zone-1 {
					display: flex;

					& > div {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 0 20px;
						min-width: 300px;
						min-height: 80px;
						background-color: white;
						font-size: 1.1rem;
						text-align: center;

						img {
							width: 40px;
						}

						strong {
							font-size: 1.3rem;
						}

						&.reseller {
							span {
								margin-top: 5px;
								font-size: 0.7rem;
								font-weight: bold;
							}
						}
					}

					& > div:nth-of-type(2) {
						border-left: 1px solid #d3d3d3;
						border-right: 1px solid #d3d3d3;
					}

					.edit-block {
						display: flex;
						gap: 5px;

						button {
							background: none;
							border: none;

							&:hover {
								cursor: pointer;
								color: var(--dark-blue-color);
							}
						}
					}

					.initial-value {
						font-size: 0.8rem;
					}
				}

				.zone-2 {
					display: flex;

					div {
						background-color: grey;
						padding: 10px 30px;
						color: white;
						border-radius: 20px;

						strong {
							font-size: 1.3rem;
						}
					}

					.status {
						display: flex;
						align-items: center;

						i {
							font-size: 1.7rem;
							font-weight: bold;

							&.pi-check {
								color: #19ab27;
							}

							&.pi-times {
								color: #9d1212;
							}
						}

						strong {
							margin-left: 10px;
						}
					}

					.stock,
					.expiration {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-left: 30px;

						span {
							font-size: 0.8rem;
						}
					}

					.stock {
						.different {
							color: orange;
						}
					}
				}

				.serial {
					display: flex;
					align-items: center;
					padding: 10px 70px;
					background-color: white;
					border-radius: 25px;
				}

				.zone-3 {
					display: flex;
					gap: 60px;

					.others-info {
						div {
							display: flex;
							justify-content: space-between;
							padding: 10px 20px;
							min-width: 350px;
							background-color: white;
							font-size: 0.8rem;

							&:nth-of-type(2n) {
								background-color: #e9e9e9;
							}

							&.license-id {
								span:nth-of-type(2) {
									display: flex;
									align-items: center;
								}
							}
						}
					}

					.workflow-zone {
						label {
							display: block;
						}
					}
				}
			}

			.license-modal-tab-menu {
				display: flex;
				justify-content: space-between;

				span {
					padding: 10px 0;
					width: 100%;
					text-align: center;
					border-bottom: 1px solid #dbdbdb;
					cursor: pointer;
				}

				.active {
					color: var(--dark-blue-color);
					border-bottom: 1px solid var(--dark-blue-color);
				}
			}

			.workflow-archive-zone {
				padding: 5px;
				width: 400px;
				height: 628px;
				overflow: auto;

				.workflow-archive {
					margin-bottom: 10px;
					padding: 10px;
					background-color: #dbdbdb;
					border-radius: 10px;
					white-space: pre-wrap;
					word-break: break-all;

					span {
						display: block;
					}

					.workflow-archive-footer {
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}
	}
}

.bitdefender-popup-background {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background-color: rgba(59, 130, 246, 0.8);

	.bitdefender-popup {
		background-color: white;
		padding: 20px;
		border-radius: 10px;

		.bitdefender-popup-header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			h1 {
				font-size: 1.2em;
				margin: 0;
			}

			.bitdefender-popup-close-button {
				width: 30px;
				height: 30px;
				padding: 5px;
				background: unset;
				color: var(--dark-blue-color);
				border: 1px solid var(--dark-blue-color);

				&:hover {
					background-color: var(--dark-blue-color);
					color: white;
				}
			}
		}

		input {
			margin-right: 10px;
		}

		.bitdefender-popup-send-button {
			background-color: var(--dark-blue-color);

			&:hover {
				background-color: var(--main-blue-color);
			}
		}
	}
}

.workflow-form {
	display: flex;
	gap: 10px;
	margin: 0 !important;

	& > div {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.workflow-form-input,
	.workflow-form-select {
		width: 200px;
	}

	.workflow-form-input {
		height: 38px;
	}

	.workflow-form-buttons {
		align-self: flex-end;
		padding-bottom: 2px;

		button {
			width: 120px;
			height: 25px;
			padding: 5px;
			background-color: var(--dark-blue-color);
			color: white;
			border: none;

			&:hover {
				background-color: var(--main-blue-color);
				cursor: pointer;
			}
		}
	}
}

header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 2;
	padding: 0px 20px;
	box-shadow: 0px -3px 10px black;

	h1 {
		color: var(--main-blue-color);
		font-weight: normal;
	}

	& > div {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.app-status {
		&:hover {
			.app-status-result {
				display: flex;
				flex-direction: column;
			}
		}

		.app-status-result {
			position: absolute;
			display: none;
			padding: 10px;
			background-color: white;
			border: 1px solid var(--main-blue-color);
			border-radius: 10px;

			span {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}
	}

	.profile {
		position: relative;
		z-index: 3;

		div {
			display: flex;
			align-items: center;
			padding-left: 15px;
			color: var(--main-blue-color);
			font-size: 16px;
			font-weight: bold;
			border-radius: 5px 5px 0px 0px;
		}

		button {
			display: none;
			position: absolute;
			width: 100%;
			padding: 5px;
			background-color: white;
			color: var(--main-blue-color);
			border: 1px solid var(--main-blue-color);
			border-radius: 0px 0px 5px 5px;
			cursor: pointer;
		}

		&:hover {
			button {
				display: block;
			}

			div {
				border: 1px solid var(--main-blue-color);
			}
		}

		button:hover {
			background-color: var(--main-blue-color);
			color: white;
		}
	}
}

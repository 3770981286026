.login-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: var(--dark-blue-color);

	.connection {
		width: 550px;
		color: white;
		text-align: center;
		border: 1px solid white;
		border-radius: 20px;
		padding-bottom: 30px;

		img {
			width: 200px;
		}

		h1 {
			margin-top: 0;
		}

		.p-button {
			color: white !important;

			&:hover {
				color: var(--light-blue-color) !important;
			}
		}
	}
}
